import { defineStore } from "pinia";
import useUserStore from "./user";
import dayjs from "~/lib/dayjs";
import type {
  DataType,
  ResponseType,
  PointHistoryCardType,
  PointType,
} from "~/types/PointHistory";
import type {
  DataTypeLoyaltyLevel,
  ResponseTypeLoyaltyLevel,
  LoyaltyLevelType,
} from "~/types/LoyaltyLevels";
import type { LoyaltyLevelTier } from "~/types/User";
import type {
  ResponseTypeVoucher,
  DataTypeVoucher,
  VoucherType,
  VoucherCardType,
} from "~/types/UsersVoucher";
export const usePointHistoryStore = defineStore("pointHistory", {
  state: () => {
    return {
      earn: {
        isLoading: false,
        anyNextPage: false,
        currentPageNumber: 1,
        data: [] as PointHistoryCardType[],
      },
      used: {
        isLoading: false,
        anyNextPage: false,
        currentPageNumber: 1,
        data: [] as PointHistoryCardType[],
      },
    };
  },

  actions: {
    rebuildData(data: DataType, typePoint: string): PointHistoryCardType[] {
      const rebuildData = data.map((item: any) => {
        const pointHistory = {
          bookingId: "",
          isPointFromBooking: false,
          point: 0,
          createdAt: "",
          description: "",
          typePoint: "used",
          restaurantSlug: "",
          title: "",
        };
        const { createdAt, point, description, isToBeEarned, title } =
          item.attributes;
        pointHistory.point = point;
        pointHistory.title = title;
        pointHistory.createdAt = createdAt;
        pointHistory.description = description;
        pointHistory.typePoint = isToBeEarned ? "earn" : typePoint;
        const { data: reservationData } = item.relationships.reservation;
        const { data: restaurantData } = item.relationships.restaurant;
        if (restaurantData) {
          const { slug } = restaurantData;
          pointHistory.restaurantSlug = slug;
        }
        if (reservationData) {
          const { id, type } = reservationData;
          const isPointFromBooking = type === "reservations";
          if (isPointFromBooking) {
            pointHistory.description = "Booking ID :";
            pointHistory.isPointFromBooking = isPointFromBooking;
            pointHistory.bookingId = id;
          }
        }
        return pointHistory;
      });

      const pointsGroup = rebuildData.reduce((points: any, point) => {
        const date = dayjs(point.createdAt).format("DD/MM/YYYY");
        if (!points[date]) {
          points[date] = [];
        }
        points[date].push(point);
        return points;
      }, {});

      // Edit: to add it in the array format instead
      const groupedDataBySameDate = Object.keys(pointsGroup).map((date) => {
        const pointHistory = {
          date: "",
          data: [],
        };
        const currentDate = dayjs().format("DD/MM/YYYY");
        const isToday = currentDate === date;
        pointHistory.date = isToday ? "Today" : date;
        pointHistory.data = pointsGroup[date];

        return pointHistory;
      });

      return groupedDataBySameDate;
    },
    async getPointHistory({ loadMore = false, type = "earn" }) {
      if (!loadMore) {
        this[type as keyof PointType].isLoading = true;
      }
      try {
        const userStore = useUserStore();
        const { accessToken } = userStore;
        const { getPointHistory } = await import("~/api/user/getPointHistory");
        const { data } = await getPointHistory({
          accessToken,
          pageNumber: this[type as keyof PointType].currentPageNumber,
          pageSize: 10,
        });
        const { data: responseData, links } = data as ResponseType;
        const { next } = links;
        this[type as keyof PointType].anyNextPage = next !== null;
        const selecedType = type === "earn" ? "earned" : "used";
        const rebuildData = this.rebuildData(responseData, selecedType);
        if (!loadMore) {
          this[type as keyof PointType].data = rebuildData;
          return;
        }
        this[type as keyof PointType].data.push(...rebuildData);
      } catch (error) {
        console.log(error);
      } finally {
        this[type as keyof PointType].isLoading = false;
      }
    },
  },
});

export const useLoyaltyLevelStore = defineStore("loyaltyLevel", {
  state: () => {
    return {
      initDataHasBeenLoaded: false,
      isLoading: false,
      data: [] as LoyaltyLevelType[],
    };
  },

  actions: {
    rebuildData(data: DataTypeLoyaltyLevel[]): LoyaltyLevelType[] {
      const rebuildData = data.map((item: any) => {
        const loyaltyData = {
          id: "",
          label: "" as LoyaltyLevelTier,
          active: false,
          rank: 0,
          pointEarning: {
            spentEvery100: 0,
            reviewWithPhotos: 0,
            referAfriend: 0,
          },
          qualification: {
            totalSpend: 0,
            totalReservations: 0,
          },
          regain: {
            totalSpend: 0,
            totalReservations: 0,
          },
          promoCodeValue: {
            first5reviews: 0,
          },
        };
        const {
          name,
          benefit,
          rank,
          qualification: { totalSpend, totalReservations },
          regain: {
            totalSpend: totalSpendRegain,
            totalReservations: totalReservationsRegain,
          },
        } = item.attributes;
        const { id } = item;
        const lowerCaseName = name.toLowerCase();
        loyaltyData.id = id;
        loyaltyData.qualification.totalSpend = totalSpend;
        loyaltyData.qualification.totalReservations = totalReservations;
        loyaltyData.regain.totalSpend = totalSpendRegain;
        loyaltyData.regain.totalReservations = totalReservationsRegain;
        loyaltyData.label = lowerCaseName;
        loyaltyData.rank = rank;
        benefit.forEach((element: any) => {
          switch (element.category) {
            case "benefits_dine_in_point":
              loyaltyData.pointEarning.spentEvery100 = element.value;
              break;
            case "benefits_review_with_photos":
              loyaltyData.pointEarning.reviewWithPhotos = element.value;
              break;
            case "benefits_referral_point":
              loyaltyData.pointEarning.referAfriend = element.value;
              break;
            case "benefits_five_reviews_point":
              loyaltyData.promoCodeValue.first5reviews = element.value;
              break;
          }
        });
        return loyaltyData;
      });

      return rebuildData.filter((data) => data.label !== "hunger");
    },
    async getLoyaltyLevel() {
      if (this.initDataHasBeenLoaded) {
        return;
      }
      try {
        this.isLoading = true;
        const { getLoyaltyLevel } = await import("~/api/user/getLoyaltyLevels");
        const { data } = await getLoyaltyLevel();
        this.initDataHasBeenLoaded = true;
        const { data: responseData } = data as ResponseTypeLoyaltyLevel;
        const rebuildData = this.rebuildData(responseData);
        this.data = rebuildData;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
export const useUsersVoucher = defineStore("usersVoucher", {
  state: () => {
    return {
      redeemed: {
        initDataHasBeenLoaded: false,
        isLoading: false,
        hasNextPage: false,
        currentPageNumber: 1,
        data: [] as VoucherCardType[],
      },
      unredeemed: {
        initDataHasBeenLoaded: false,
        isLoading: false,
        hasNextPage: false,
        currentPageNumber: 1,
        data: [] as VoucherCardType[],
      },
      expired: {
        initDataHasBeenLoaded: false,
        isLoading: false,
        hasNextPage: false,
        currentPageNumber: 1,
        data: [] as VoucherCardType[],
      },
    };
  },

  actions: {
    rebuildData(
      data: DataTypeVoucher[],
      sectionType: string
    ): VoucherCardType[] {
      const rebuildVoucher = data.map((item) => {
        const voucher = {
          id: "",
          active: false,
          validityPeriod: "",
          name: "",
          qrcode: "",
          ticketGroupId: 0,
          ticketCode: "",
          image: "",
          redeemedAt: "",
          amount: "",
          isRedeemed: false,
          isExpired: false,
          restaurant: {
            name: "",
            phoneNumber: "62844222122",
          },
        };
        const {
          active,
          name,
          qrcode,
          redeemedAt,
          ticketCode,
          validEndDate,
          validStartDate,
          ticketGroupId,
          restaurantName,
          amount: { format },
        } = item.attributes;
        const startDate = dayjs(validStartDate).format("D MMM");
        const endDate = dayjs(validEndDate).format("D MMM YYYY");
        voucher.validityPeriod = `${startDate} -  ${endDate}`;
        voucher.id = item.id;
        voucher.ticketCode = ticketCode;
        voucher.isExpired =
          typeof validEndDate === "string" &&
          dayjs(validEndDate, "YYYY-MM-DD").isBefore(dayjs(), "day");
        voucher.redeemedAt = redeemedAt
          ? dayjs(redeemedAt).format("DD/MM/YYYY")
          : "";
        voucher.name = name;
        voucher.ticketGroupId = ticketGroupId;
        voucher.active = active;
        voucher.amount = format;
        voucher.qrcode = qrcode;
        voucher.restaurant.name = restaurantName || "";
        voucher.isRedeemed = sectionType === "redeemed";
        return voucher;
      });
      return rebuildVoucher;
    },
    async getUsersVoucher(params: any) {
      const { sectionType, loadMore, types } = params;
      try {
        this[types as keyof VoucherType].isLoading = !loadMore;
        const userStore = useUserStore();
        const { accessToken } = userStore;
        const payload = {
          accessToken,
          pageNumber: this[types as keyof VoucherType].currentPageNumber,
          pageSize: 5,
          sectionType,
        };
        const { getUsersVoucher } = await import("~/api/user/getUsersVoucher");
        const { data } = await getUsersVoucher(payload);
        const {
          data: responseData,
          links: { next },
        } = data as ResponseTypeVoucher;
        this[types as keyof VoucherType].hasNextPage = next !== null;
        const rebuildData = this.rebuildData(responseData, sectionType);
        this[types as keyof VoucherType].initDataHasBeenLoaded = true;
        if (!loadMore) {
          this[types as keyof VoucherType].data = rebuildData;
          return;
        }
        this[types as keyof VoucherType].data.push(...rebuildData);
      } catch (error) {
        console.log(error);
      } finally {
        this[types as keyof VoucherType].isLoading = false;
      }
    },
  },
  getters: {
    unredeemedVoucher(state) {
      return state.unredeemed.data.filter((voucher) => !voucher.isExpired);
    },
    redeemedVoucher(state) {
      return state.redeemed.data;
    },
    expiredVoucher(state) {
      return state.expired.data.filter((voucher) => voucher.isExpired);
    },
    hasExpiredVoucher() {
      return (
        Array.isArray(this.expiredVoucher) && this.expiredVoucher.length > 0
      );
    },
  },
});
